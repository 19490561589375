import React, { Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { useAuth } from "../stores/AuthContext";

import EjoyshopLoader from "../components/loader/EjoyshopLoader";

const AdminPanelEditPlan = React.lazy(() =>
  import("../components/adminPanel/AdminPanelEditPlan")
);

const PageNotFound = React.lazy(() => import("../helpers/PageNotFound"));

const AdminPanelVendorsList = React.lazy(() =>
  import("../components/adminPanel/vendorModules/AdminPanelVendorsList")
);
const AdminPanelCreateVendor = React.lazy(() =>
  import("../components/adminPanel/vendorModules/AdminPanelCreateVendor")
);
const AdminPanelEmployees = React.lazy(() =>
  import("../components/adminPanel/AdminPanelEmployees")
);
const AdminPanelCreateEmployee = React.lazy(() =>
  import("../components/adminPanel/employeesModules/AdminPanelCreateEmployee")
);
const AdminPanelEditEmployee = React.lazy(() =>
  import("../components/adminPanel/employeesModules/AdminPanelEditEmployee")
);
const AdminPanelEditVendor = React.lazy(() =>
  import("../components/adminPanel/vendorModules/AdminPanelEditVendor")
);

const AdminPanelLayout = React.lazy(() =>
  import("../layouts/AdminPanelLayout")
);
const AdminPanelDashboard = React.lazy(() =>
  import("../components/adminPanel/AdminPanelDashboard")
);
const AdminPanelVendors = React.lazy(() =>
  import("../components/adminPanel/AdminPanelVendors")
);
const AdminPanelAdminRoles = React.lazy(() =>
  import("../components/adminPanel/AdminPanelAdminRoles")
);
const AdminPanelUsers = React.lazy(() =>
  import("../components/adminPanel/AdminPanelUsers")
);
const AdminPanelCategories = React.lazy(() =>
  import("../components/adminPanel/AdminPanelCategories")
);

const AdminPanelNotifications = React.lazy(() =>
  import("../components/adminPanel/AdminPanelNotifications")
);
const AdminPanelOrders = React.lazy(() =>
  import("../components/adminPanel/AdminPanelOrders")
);
const AdminPanelCreditHistory = React.lazy(() =>
  import("../components/adminPanel/AdminPanelCreditHistory")
);
const AdminPanelPricingPlans = React.lazy(() =>
  import("../components/adminPanel/AdminPanelPricingPlans")
);
const AdminPanelReports = React.lazy(() =>
  import("../components/adminPanel/AdminPanelReports")
);
const AdminPanelSettings = React.lazy(() =>
  import("../components/adminPanel/AdminPanelSettings")
);
const AdminPanelChat = React.lazy(() =>
  import("../components/adminPanel/AdminPanelChat")
);
const AdminPanelSupport = React.lazy(() =>
  import("../components/adminPanel/AdminPanelSupport")
);

const EmployeePanelDashboard = React.lazy(() =>
  import("../components/employeePanel/EmployeePanelDashboard")
);
const EmployeePanelLayout = React.lazy(() =>
  import("../layouts/EmployeePanelLayout")
);

const Categories = React.lazy(() =>
  import("../components/adminPanel/categories/Categories")
);

const SubCategories = React.lazy(() =>
  import("../components/adminPanel/categories/SubCategories")
);
const CreateSubCategories = React.lazy(() =>
  import("../components/adminPanel/categories/CreateSubCategories")
);

const CreateCategories = React.lazy(() =>
  import("../components/adminPanel/categories/CreateCategories")
);
const EditCategories = React.lazy(() =>
  import("../components/adminPanel/categories/EditCategories")
);
const Language = React.lazy(() =>
  import("../components/adminPanel/categories/Language")
);

const Occasion = React.lazy(() =>
  import("../components/adminPanel/categories/Occasion")
);

const EditSubCategories = React.lazy(() =>
  import("../components/adminPanel/categories/EditSubCategories")
);

const CategoriesList = React.lazy(() =>
  import("../components/adminPanel/categories/CategoriesList")
);

const AdminPanelMeetings = React.lazy(() =>
  import("../components/adminPanel/AdminPanelMeetings")
);
const AdminPanelAddPlans = React.lazy(() =>
  import("../components/adminPanel/AdminPanelAddPlans")
);
const AdminPanelParentPricingPlans = React.lazy(() =>
  import("../components/adminPanel/AdminPanelParentPricingPlans")
);
const UserListView = React.lazy(() =>
  import("../components/adminPanel/users/UserListView")
);
const EditUser = React.lazy(() =>
  import("../components/adminPanel/users/editUser/EditUser")
);
const UserOrders = React.lazy(() =>
  import("../components/adminPanel/users/editUser/UserOrders")
);
const UserCredits = React.lazy(() =>
  import("../components/adminPanel/users/editUser/UserCredits")
);
const UserEditInformation = React.lazy(() =>
  import("../components/adminPanel/users/editUser/UserEditInformation")
);
const OrderReport = React.lazy(() =>
  import("../components/adminPanel/reports/OrderReport")
);
const EarningsReport = React.lazy(() =>
  import("../components/adminPanel/reports/EarningsReport")
);
const SalesReport = React.lazy(() =>
  import("../components/adminPanel/reports/SalesReport")
);
const AdminPanelRoleRegistrationForm = React.lazy(() =>
  import("../components/adminPanel/AdminPanelRegistrationForm")
);
const AdminPanelNotificationsForm = React.lazy(() =>
  import("../components/adminPanel/notification/AdminPanelNotificationsForm")
);
const AdminPanelEditNotification = React.lazy(() =>
  import("../components/adminPanel/notification/AdminPanelEditNotification")
);
const CreateShipment = React.lazy(() =>
  import("../components/adminPanel/shipmentModules/CreateShipment")
);
const VendorShipment = React.lazy(() =>
  import("../components/adminPanel/AdminPanelShipment")
);
const ShipmentList = React.lazy(() =>
  import("../components/adminPanel/shipmentModules/ShipmentList")
);
const AdminPanelOffers = React.lazy(() =>
  import("../components/adminPanel/AdminPanelOffers")
);
const Plans = React.lazy(() =>
  import("../components/adminPanel/offerModules/Plans")
);
const OfferRequests = React.lazy(() =>
  import("../components/adminPanel/offerModules/OfferRequests")
);
const AddOffers = React.lazy(() =>
  import("../components/adminPanel/offerModules/AddOffers")
);
const AddOffer = React.lazy(() =>
  import("../components/adminPanel/offerModules/AddOffer")
);
const ShopDetails = React.lazy(() =>
  import("../components/adminPanel/settingsModules/ShopDetails")
);
const ProfileSettings = React.lazy(() =>
  import("../components/adminPanel/settingsModules/ProfileSettings")
);
const Settings = React.lazy(() =>
  import("../components/adminPanel/settingsModules/Settings")
);
const ShipmentTrack = React.lazy(() =>
  import("../components/adminPanel/shipmentModules/ShipmentTrack")
);
const AdminPanelSupportChat = React.lazy(() =>
  import("../components/adminPanel/AdminPanelSupportChat")
);
const AddOfferPlan = React.lazy(() =>
  import("../components/adminPanel/offerModules/AddOfferPlan")
);
const OfferParent = React.lazy(() =>
  import("../components/adminPanel/offerModules/OfferParent")
);
const AuthPage = React.lazy(() => import("../pages/login/AuthPage"));
const LoginCard = React.lazy(() => import("../pages/login/LoginCard"));
const ForgotPassord = React.lazy(() => import("../pages/login/ForgotPassord"));
const VendorSupportList = React.lazy(() =>
  import("../components/adminPanel/supportModules/VendorSupportList")
);
const UserSupportList = React.lazy(() =>
  import("../components/adminPanel/supportModules/UserSupportList")
);

const AdminProtectedRoute = ({ component: Component }) => {
  const { currentUser, userRole } = useAuth();
  return (
    <>
      {currentUser && userRole?.admin ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <Component />
        </Suspense>
      ) : (
        <Navigate to='/auth' replace />
      )}
    </>
  );
};
const SubAdminProtectedRoute = ({ component: Component }) => {
  const { currentUser, userRole, userInfo } = useAuth();
  const permissions = userInfo?.permissions;

  const permissionFunc = () => {
    switch (Component) {
      case AdminPanelSettings:
      case ProfileSettings:
      case Settings:
        return permissions?.settings;
      case AdminPanelDashboard:
        return permissions?.dashboard;
      case AdminPanelVendorsList:
      case AdminPanelVendors:

      // Handle AdminPanelVendorsList case

      case AdminPanelCreateVendor:
      // Handle AdminPanelCreateVendor case

      case AdminPanelEditVendor:
        return permissions?.vendors;
      // Handle AdminPanelEditVendor case

      case AdminPanelEmployees:
      // Handle AdminPanelEmployees case

      case AdminPanelCreateEmployee:
      // Handle AdminPanelCreateEmployee case

      case AdminPanelEditEmployee:
        // Handle AdminPanelEditEmployee case
        return permissions?.employees;

      case AdminPanelAdminRoles:
      // Handle AdminPanelAdminRoles case

      case AdminPanelRoleRegistrationForm:
        // Handle AdminPanelRoleRegistrationForm case
        return permissions?.subAdmin;
      case AdminPanelUsers:
      // Handle AdminPanelUsers case

      case UserListView:
      // Handle UserListView case

      case EditUser:
      // Handle EditUser case

      case UserOrders:
      // Handle UserOrders case

      case UserCredits:
      // Handle UserCredits case

      case UserEditInformation:
        // Handle UserEditInformation case
        return permissions?.users;
      case AdminPanelCategories:
      // Handle AdminPanelCategories case

      case CategoriesList:
      // Handle CategoriesList case

      case Categories:
      // Handle Categories case

      case SubCategories:
      // Handle SubCategories case

      case Language:
      // Handle Language case

      case Occasion:
      // Handle Occasion case

      case CreateSubCategories:
      // Handle CreateSubCategories case

      case EditSubCategories:
      // Handle EditSubCategories case

      case CreateCategories:
      // Handle CreateCategories case

      case EditCategories:
        // Handle EditCategories case
        return permissions?.categories;
      case AdminPanelNotifications:
      // Handle AdminPanelNotifications case

      case AdminPanelNotificationsForm:
      // Handle AdminPanelNotificationsForm case

      case AdminPanelEditNotification:
        // Handle AdminPanelEditNotification case
        return permissions?.notifications;
      case AdminPanelOrders:
        // Handle AdminPanelOrders case
        return permissions?.orders;
      case AdminPanelMeetings:
        // Handle AdminPanelMeetings case
        return permissions?.meetings;
      case AdminPanelCreditHistory:
        // Handle AdminPanelCreditHistory case
        return permissions?.credits;
      case AdminPanelParentPricingPlans:
      // Handle AdminPanelParentPricingPlans case

      case AdminPanelPricingPlans:
      // Handle AdminPanelPricingPlans case

      case AdminPanelAddPlans:
        // Handle AdminPanelAddPlans case
        return permissions?.pricingPlans;
      case AdminPanelOffers:
      // Handle AdminPanelOffers case

      case OfferParent:
      // Handle OfferParent case

      case Plans:
      // Handle Plans case

      case OfferRequests:
      // Handle OfferRequests case

      case AddOffers:
      // Handle AddOffers case

      case AddOfferPlan:
      // Handle AddOfferPlan case

      case AddOffer:
        // Handle AddOffer case
        return permissions?.offers;
      case AdminPanelEditPlan:

      //handle handle offer plan
      case AdminPanelReports:
      // Handle AdminPanelReports case

      case SalesReport:
      // Handle SalesReport case

      case EarningsReport:
      // Handle EarningsReport case

      case OrderReport:
        // Handle OrderReport case
        return permissions?.reports;
      case VendorShipment:
      // Handle VendorShipment case

      case ShipmentList:
      // Handle ShipmentList case

      case CreateShipment:
      // Handle CreateShipment case

      case ShipmentTrack:
        // Handle ShipmentTrack case
        return permissions?.shipments;

      case AdminPanelChat:
        // Handle AdminPanelChat case
        return permissions?.chat;
      case AdminPanelSupport:
      // Handle AdminPanelSupport case

      case AdminPanelSupportChat:
      case VendorSupportList:
      case UserSupportList:
        // Handle AdminPanelSupportChat case
        return permissions?.support;
      default:
        // Handle default case
        return false;
    }
  };

  const isPermitted = permissionFunc();

  return (
    <>
      {currentUser && userRole?.subAdmin && isPermitted ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <Component />
        </Suspense>
      ) : (
        <Navigate to='/auth' replace />
      )}
    </>
  );
};

const EmployeePrivateRoute = ({ component: Component }) => {
  const { currentUser, userRole } = useAuth();

  return (
    <>
      {currentUser && userRole?.employee ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <Component />
        </Suspense>
      ) : (
        <Navigate to='/auth' replace />
      )}
    </>
  );
};

const GetRoleBasedLayout = () => {
  const { currentUser, userRole } = useAuth();

  return (
    <>
      {userRole?.employee ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <EmployeePanelLayout />
        </Suspense>
      ) : userRole?.admin || userRole?.subAdmin ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <AdminPanelLayout />
        </Suspense>
      ) : (
        <Navigate to='/auth' replace />
      )}
    </>
  );
};

const GetRoleBasedDashboard = () => {
  const { currentUser, userRole } = useAuth();
  return (
    <>
      {userRole?.employee ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <EmployeePanelDashboard />
        </Suspense>
      ) : userRole?.admin ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <AdminPanelDashboard />
        </Suspense>
      ) : userRole?.subAdmin ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <SubAdminProtectedRoute component={AdminPanelDashboard} />
        </Suspense>
      ) : (
        <Navigate to='/auth' replace />
      )}
    </>
  );
};
const RoleBasedVendorPanel = () => {
  const { currentUser, userRole } = useAuth();
  return (
    <>
      {userRole?.employee ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <EmployeePrivateRoute component={AdminPanelVendors} />
        </Suspense>
      ) : userRole?.admin ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <AdminProtectedRoute component={AdminPanelVendors} />
        </Suspense>
      ) : userRole?.subAdmin ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <SubAdminProtectedRoute component={AdminPanelVendors} />
        </Suspense>
      ) : (
        <Navigate to='/auth' replace />
      )}
    </>
  );
};

const RoleBasedRender = ({ component }) => {
  const { currentUser, userRole } = useAuth();
  return (
    <>
      {userRole?.employee ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <EmployeePrivateRoute component={component} />
        </Suspense>
      ) : userRole?.admin ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <AdminProtectedRoute component={component} />
        </Suspense>
      ) : userRole?.subAdmin ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <SubAdminProtectedRoute component={component} />
        </Suspense>
      ) : (
        <Navigate to='/auth' replace />
      )}
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/auth",
    element: (
      <Suspense fallback={<EjoyshopLoader />}>
        <AuthPage />{" "}
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <LoginCard />
          </Suspense>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <ForgotPassord />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<EjoyshopLoader />}>
        <GetRoleBasedLayout />
      </Suspense>
    ),
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <GetRoleBasedDashboard />
          </Suspense>
        ),
      },
      {
        path: "vendors",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedVendorPanel />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelVendorsList} />
              </Suspense>
            ),
          },
          {
            path: "create",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelCreateVendor} />
              </Suspense>
            ),
          },
          {
            path: "edit/:vendorId",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelEditVendor} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "employees",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelEmployees} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelCreateEmployee} />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelEditEmployee} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "sub-admins",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelAdminRoles} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelRoleRegistrationForm} />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelRoleRegistrationForm} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "users",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelUsers} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={UserListView} />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={EditUser} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <UserOrders />
                  </Suspense>
                ),
              },
              {
                path: "credits",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <UserCredits />
                  </Suspense>
                ),
              },
              {
                path: "profile",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <UserEditInformation />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "categories",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelCategories} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={CategoriesList} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <RoleBasedRender component={Categories} />
                  </Suspense>
                ),
              },
              {
                path: "sub-categories",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <RoleBasedRender component={SubCategories} />
                  </Suspense>
                ),
              },
              {
                path: "languages",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <RoleBasedRender component={Language} />
                  </Suspense>
                ),
              },
              {
                path: "occasion",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <RoleBasedRender component={Occasion} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "create-subcategory",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={CreateSubCategories} />
              </Suspense>
            ),
          },
          {
            path: "edit-subcategory/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={EditSubCategories} />
              </Suspense>
            ),
          },
          {
            path: "create-category",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={CreateCategories} />
              </Suspense>
            ),
          },
          {
            path: "edit-category/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={EditCategories} />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "notifications",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelNotifications} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelNotificationsForm} />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelEditNotification} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "orders",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelOrders} />
          </Suspense>
        ),
      },
      {
        path: "meetings",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelMeetings} />
          </Suspense>
        ),
      },
      {
        path: "credits-history",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelCreditHistory} />
          </Suspense>
        ),
      },
      {
        path: "pricing-plans",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelParentPricingPlans} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelPricingPlans} />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelEditPlan} />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AdminPanelAddPlans} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "offers",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelOffers} />
          </Suspense>
        ),

        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={OfferParent} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <RoleBasedRender component={Plans} />
                  </Suspense>
                ),
              },
              {
                path: "offer-requests",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <RoleBasedRender component={OfferRequests} />
                  </Suspense>
                ),
              },
              {
                path: "add-offers",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <RoleBasedRender component={AddOffers} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AddOfferPlan} />
              </Suspense>
            ),
          },

          {
            path: "add-offer",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AddOffer} />
              </Suspense>
            ),
          },
          {
            path: "create-offer-plan",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={AddOfferPlan} />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "reports",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelReports} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={SalesReport} />
              </Suspense>
            ),
          },
          {
            path: "earning-report",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={EarningsReport} />
              </Suspense>
            ),
          },
          {
            path: "order-report",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={OrderReport} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "shipment",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            {" "}
            <RoleBasedRender component={VendorShipment} />
          </Suspense>
        ),

        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                {" "}
                <RoleBasedRender component={ShipmentList} />
              </Suspense>
            ),
          },
          {
            path: "create-shipment",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={CreateShipment} />
              </Suspense>
            ),
          },
          {
            path: "shipment-track/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={ShipmentTrack} />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={CreateShipment} />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "settings",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelSettings} />
          </Suspense>
        ),

        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={ProfileSettings} />
              </Suspense>
            ),
          },

          {
            path: "additional-settings",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={Settings} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "chat",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelChat} />
          </Suspense>
        ),
      },
      {
        path: "support",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelSupport} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={VendorSupportList} />
              </Suspense>
            ),
          },
          {
            path: "user",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <RoleBasedRender component={UserSupportList} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "support-chat",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RoleBasedRender component={AdminPanelSupportChat} />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: "*",
    element: <PageNotFound />,
  },
]);
