import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

let date = moment().format("DD-MM-YYYY");
let time = moment().format("HH:mm");


const initialState = {
  preBussinessInformation: {
    _PAN: null,
    _IEC: null,
    _GSTIN: null,
  },
  mainBussinessInformation: {
    bussinessPAN: null,
    importAndExportCode: null,
    _GSTIN: null,
    legalBussinessName: null,
    dateOfRegistration: null,
    bussinessConstitution: null,
    taxPayerType: null,
    _GSTINStatus: null,
    bussinessAddress: null,
    bussinessState: null,
    bussinessLocality: null,
    bussinessCity: null,
    bussinessPinCode: null,
    shopName: null,
  },
  preOwnerDetails: {
    aadharNumber: null,
    personalPAN: null,
  },
  postOwnerDetails: {
    aadhaarRefId: null,
    aadharNumber: null,
    personalPAN: null,
    aadharOTP: null,
    panHolderFullName: null,
  },
  mainOwnerDetails: {
    aadharNumber: null,
    personalPAN: null,
    panHolderFullName: null,
    aadharHolderName: null,
    aadharHolderGender: null,
    aadharHolderDateOfBirth: null,
    aadharHolderFatherName: null,
    aadharHolderAddress: null,
    aadharHolderAddressState: null,
    aadharHolderPinCode: null,
  },
  bankAccountDetails: {
    bankAccountNumber: null,
    bankIFSCCode: null,
    bankAccountHolderName: null,
    bankName: null,
    bankCity: null,
    bankBranch: null,
    bankAccountStatus: null,
  },
  moreInformation: {
    name: null,
    email: null,
    mobileNumber: null,
    alternativeMobileNumber: null,
    internationalSeller: null,
    domesticSeller: null,
    commisionSlab: null,
    shopActive: null,
    shopVerified: null,
    shopPremium: null,
    establishmentYear: null,
  },
  documents: {
    companyLogo: null,
    _GSTCertificate: null,
    _IECCertificate: null,
    bussinessPANCard: null,
    cancelledBankCheque: null,
    ownerAadharCard: null,
    ownerPANCard: null,
    agreementCopy: null,
  },
  onBoardingInformation: {
    shopOnBoardingDate: null,
    shopOnBoardingTime: null,
    empCode: null,
    empName: null,
    shopImages: [],
    shopCoverImage: null,
  },
  vendorFetchedDocuments: {
    companyLogoX: null,
    _GSTCertificateX: null,
    _IECCertificateX: null,
    bussinessPANCardX: null,
    cancelledBankChequeX: null,
    ownerAadharCardX: null,
    ownerPANCardX: null,
    agreementCopyX: null,
    shopImagesX: null,
    shopCoverImageX: null,
  },

  formSaveLoaderBtn: false,
  ownerDetailsEdit: false,
  employeeUpdated: false,
  vendorIds: {
    vendorMongoId: null,
    vendorCustomId: null,
  },
};

export const vendorRegistrationSlice = createSlice({
  name: "vendorRegistration",
  initialState,
  reducers: {
    updatePreBussinessInformation(state, action) {
      state.preBussinessInformation = {
        ...state.preBussinessInformation,
        ...action.payload,
      };
    },
    updateMainBussinessInformation(state, action) {
      state.mainBussinessInformation = {
        ...state.mainBussinessInformation,
        ...action.payload,
      };
    },
    updatePreOwnerDetails(state, action) {
      state.preOwnerDetails = { ...state.preOwnerDetails, ...action.payload };
    },
    updatePostOwnerDetails(state, action) {
      state.postOwnerDetails = { ...state.postOwnerDetails, ...action.payload };
    },
    updateMainOwnerDetails(state, action) {
      state.mainOwnerDetails = { ...state.mainOwnerDetails, ...action.payload };
    },
    updateBankAccountDetails(state, action) {
      state.bankAccountDetails = {
        ...state.bankAccountDetails,
        ...action.payload,
      };
    },
    updateMoreInformation(state, action) {
      state.moreInformation = { ...state.moreInformation, ...action.payload };
    },
    updateDocuments(state, action) {
      state.documents = { ...state.documents, ...action.payload };
    },
    updateOnBoardingInformation(state, action) {
      state.onBoardingInformation = {
        ...state.onBoardingInformation,
        ...action.payload,
      };
    },
    updateVendorFetchedDocuments(state, action) {
      state.vendorFetchedDocuments = {
        ...state.vendorFetchedDocuments,
        ...action.payload,
      };
    },
    updateVendorIds(state, action) {
      state.vendorIds = {
        ...state.vendorIds,
        ...action.payload,
      };
    },
    updateOnBoardingInformationImage(state, action) {
      let x = state.onBoardingInformation.shopImages;

      x.push(action.payload.shopImage);
      state.onBoardingInformation.shopImages = x;
    },
    deleteOnBoardingInformationImage(state, action) {
      let x = state.onBoardingInformation.shopImages;
      x.splice(action.payload.index, 1);
      state.onBoardingInformation.shopImages = x;
    },
    deleteShopImagesX(state, action) {
      let x = state.vendorFetchedDocuments.shopImagesX;
      x.splice(action.payload.index, 1);
      state.vendorFetchedDocuments.shopImagesX = x;
    },

    formSaveLoaderBtnUpdate(state, action) {
      state.formSaveLoaderBtn = action.payload.buttonState;
    },
    updateOwnerDetailsEdit(state, action) {
      state.ownerDetailsEdit = action.payload.ownerDetailsEdit;
    },
    employeeUpdatedFunc(state, action) {
      state.employeeUpdated = !state.employeeUpdated;
    },
    resetForm(state, action) {
      return initialState
    },
  },
});
