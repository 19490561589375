import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import {
  RouterProvider
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EjoyshopLoader from "./components/loader/EjoyshopLoader";
import { database } from "./Firebase";
import { settingUnseenGroupsChat } from "./realtimeDataBase/handlers/ChatFunctions";
import { router } from "./routes/Route";
import { useAuth } from "./stores/AuthContext";

function App() {
  const [loading, setLoading] = useState(true);
  const [loadingC, setLoadingC] = useState(false);
  
  const { userInfo } = useAuth();

  useEffect(() => {
    const images = [
      "./assets/cryptocurrency-record.png",
      "./assets/newVdo.mp4",
      "./assets/Ellipse 825.png",
      "./assets/Ellipse 826.png",
      "./assets/final 5.jpg",
      "./assets/Frame 37352.png",
      "./assets/Frame 3734.png",
      "./assets/loginAvatar.svg",
      "./assets/loginEllipse1.svg",
      "./assets/loginEllipse2.svg",
      "./assets/loginEllipse3.svg",
      "./assets/PATTERNS.png",
      "./assets/premiumFeatures.svg",
      "./assets/transaction 1 (1).png",
      "./assets/transaction 1 (2).png",
      "./assets/transaction 1.png",
      "./assets/Vector (1).png",
      "./assets/Vector (2).png",
      "./assets/Vector (3).png",
      "./assets/Vector 244.png",
      "./assets/Vector 245.png",
      "./assets/Vector.png",
      "./assets/VectorAvatar.svg",
    ];

    cacheImages(images);
  }, []);

 

  const cacheImages = async (srcArr) => {
    const promises = await srcArr.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setLoading(false);
  };

  useEffect(() => {
    const groupMembersRef = ref(database, "groups");

    const unsubscribe = onValue(groupMembersRef, async (snapshot) => {
      // The callback function will be invoked whenever the data changes
      const groupsData = snapshot.val();

      await settingUnseenGroupsChat(
        groupsData,
        userInfo?.role?.subAdmin ? userInfo?.chatId : userInfo?.userId
      );
    });

    return () => unsubscribe();
  }, [userInfo]);

  


    return (
      <div className=''>
        {loading ? <EjoyshopLoader /> : <RouterProvider router={router} />}
        <ToastContainer />
      </div>
    );
  
}

export default App;
