
import { ToWords } from "to-words";

const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Rupee",
      plural: "Rupees",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});


const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
const teens = ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

export function convertToWords(number) {
  if (number === 0) {
    return 'zero';
  }

  return toWords.convert(number);

  //return capitalizeAllWords(convertNumber(number));

  function capitalizeAllWords(str) {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  function convertNumber(num) {
    if (num < 10) {
      return ones[num];
    } else if (num >= 11 && num <= 19) {
      return teens[num - 11];
    } else if (num >= 10 && num % 10 === 0 && num < 100) {
      return tens[num / 10];
    } else if (num >= 20 && num < 100) {
      return tens[Math.floor(num / 10)] + ' ' + ones[num % 10];
    } else if (num >= 100 && num < 1000) {
      return ones[Math.floor(num / 100)] + ' hundred ' + convertNumber(num % 100);
    } else if (num >= 1000 && num < 1000000) {
      return convertNumber(Math.floor(num / 1000)) + ' thousand ' + convertNumber(num % 1000);
    } else if (num >= 1000000 && num < 1000000000) {
      return convertNumber(Math.floor(num / 1000000)) + ' million ' + convertNumber(num % 1000000);
    } else {
      // Extend as needed
      return 'Number out of range for this function';
    }
  }
}
export const PlayNotificationSound = () => {
  try {
    // Replace 'notification-sound.mp3' with the correct path to your sound file
    const audio = new Audio("https://ejoyshop-production.s3.amazonaws.com/audio/computerwav-14702.mp3");
    
    audio.play();
  } catch (error) {
    console.error("Error playing notification sound:", error);
  }
};


