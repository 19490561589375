import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./stores/AuthContext";
import store from "./stores/IndexRedux";

const root = ReactDOM.createRoot(document.getElementById("root"));
export const queryClient = new QueryClient();
root.render(
  <AuthContextProvider>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </QueryClientProvider>
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
