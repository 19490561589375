import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employeeDetails: null,
  formSaveLoaderBtn: false,
  userName: null,
  userId: null,
  searchFlow: "",
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    updateEmployeeDetails(state, action) {
      state.employeeDetails = action.payload.employeeDetails;
    },
    updateUserName(state, action) {
      state.userName = action.payload.userName;
    },
    updateUserId(state, action) {
      state.userId = action.payload.userId;
    },
    updateSearchFlow(state, action) {
      state.searchFlow = action.payload;
    },
  },
});
const appManagementState = {
  chat: {
    activeGroupId: null,
  },
  notifications: {
    personalized: [],
    adminGeneral: [],
    general: [],
    unreadPersonlizedCounts: 0,
    unreadadminGeneralCounts: 0,
    unreadGeneralCounts: 0,
    newNotification: false,
  },
};

export const appManagementSlice = createSlice({
  name: "appManagement",
  initialState: appManagementState,
  reducers: {
    updateChat(state, action) {
      state.chat = { ...state.chat, ...action.payload };
    },
    updateNotifications(state, action) {
      state.notifications = {
        ...state.notifications,
        ...action.payload,
      };
    },
  },
});
