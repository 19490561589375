
import {configureStore} from '@reduxjs/toolkit';
import { vendorRegistrationSlice } from './VendorRegistrationRedux';
import { appManagementSlice, employeeSlice } from './EmployeeRedux';


const store = configureStore({
    reducer : { vendorRegistration: vendorRegistrationSlice.reducer, employee: employeeSlice.reducer,appManagement: appManagementSlice.reducer}
});

export const vendorRegistrationActions = vendorRegistrationSlice.actions;
export const employeeActions = employeeSlice.actions;
export const appManagementActions = appManagementSlice.actions;

export default store;