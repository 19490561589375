const baseUrl = process.env.REACT_APP_BASEURL;
//const baseUrl = "http://localhost:4001/api/v1";
//const baseUrl = "https://k3qw61dm-4001.inc1.devtunnels.ms/api/v1";

//const baseUrl = "http://localhost:4001/api/v1";
//const baseUrl = "https://k3qw61dm-4001.inc1.devtunnels.ms/api/v1";
const EmployeeAuthBaseUrl = `${baseUrl}/emp`;
const SubAdminAuthBaseUrl = `${baseUrl}/sub-admin`;
const AdminAuthBaseUrl = `${baseUrl}/admin`;
export const createVendorUrl = `${baseUrl}/vendor/createVendor`;
export const verifyGSTIN = `${baseUrl}/verificationSuit/verifyGSTIN`;
export const verifyPAN = `${baseUrl}/verificationSuit/verifyPAN`;
export const generateOTPUrl = `${baseUrl}/verificationSuit/generateAadhaarOTP`;
export const verifyAadhaarOTPUrl = `${baseUrl}/verificationSuit/submitAadhaarOTP`;
export const verifyBankUrl = `${baseUrl}/verificationSuit/bankVerification`;
const APIUrls = {
  getVendors: `${baseUrl}/vendor/getVendors`,
  forgotPassword: `${baseUrl}/admin/forgot-password`,
  getCitiesAndStates: `${baseUrl}/vendor/getCitiesAndStates`,
  getUserRole: `${baseUrl}/userRoles`,
  getEmplyeeDetails: `${baseUrl}/emp/getEmployeeDetails`,
  getVendorById: `${baseUrl}/admin/vendors`,
  updateAdminProfile: `${baseUrl}/admin/update`,
  getAllVendor: `${baseUrl}/vendor`,
  updateVendor: `${baseUrl}/admin/vendors`,

  getAllEmployees: (searchKey, status, download, page) =>
    `${baseUrl}/emp/getAllEmployees?searchKey=${searchKey}&status=${status}&download=${download}&page=${page}`,
  getEmployeeById: `${baseUrl}/emp/getEmployeeById`,
  updateEmployee: `${baseUrl}/emp/updateEmp`,
  deleteEmployee: `${baseUrl}/emp/deleteEmployee`,
  getAdminDetails: `${baseUrl}/admin/getAdminDetails`,
  check_GSTIN_Exists: `${baseUrl}/vendor/check_GSTIN_Exists`,
  checkBusinessIEC_Exists: `${baseUrl}/vendor/checkBusinessIEC_Exists`,
  checkBusinessPAN_Exists: `${baseUrl}/vendor/checkBusinessPAN_Exists`,
  checkEmailExists: `${baseUrl}/vendor/checkEmailExists`,
  checkMobileNumberExists: `${baseUrl}/vendor/checkMobileNumberExists`,
  createEmp: `${baseUrl}/emp/createEmp`,
  orders: {
    getOrders: (download, searchKey, fromDate, endDate, status, page) =>
      `${baseUrl}/admin/orders?searchKey=${encodeURIComponent(
        searchKey || ""
      )}&status=${encodeURIComponent(
        status || ""
      )}&fromDate=${encodeURIComponent(
        fromDate || ""
      )}&toDate=${encodeURIComponent(
        endDate || ""
      )}&download=${download}&page=${page}`,
  },

  user: {
    fetchUsers: (searchKey, download, page) =>
      `${baseUrl}/admin/customers?searchKey=${searchKey}&download=${download}&page=${page}`,
    fetchUserById: (id) => `${baseUrl}/admin/customers/${id}`,

    fetchUserOrders: (
      id,
      searchKey,
      fromDate,
      toDate,
      status,
      download,
      page
    ) =>
      `${baseUrl}/admin/customers/${id}/orders?searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&download=${download}&page=${page}`,
    fetchUserCredits: (
      id,
      searchKey,
      fromDate,
      toDate,
      status,
      download,
      page
    ) =>
      `${baseUrl}/admin/customers/${id}/credits?searchKey=${searchKey}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&page=${page}`,
    addCreditOffer: (id) => `${baseUrl}/admin/customers/${id}/award-credits`,
  },
  plan: {
    fetchPlans: (searchKey, download, page) =>
      `${baseUrl}/admin/credits-plans?searchKey=${searchKey}&download=${download}&page=${page}`,
    addPlan: `${baseUrl}/admin/credits-plans`,
    fetchPlanById: (id) => `${baseUrl}/admin/credits-plans/${id}`,
    deleteSpecificPlan: (id) => `${baseUrl}/admin/credits-plans/${id}`,
    updateSpecificPlan: (id) => `${baseUrl}/admin/credits-plans/${id}`,
  },
  notification: {
    createNewNotification: `${baseUrl}/admin/notifications`,
    sendingAdminNotification: (id) =>
      `${baseUrl}/admin/notifications/send/${id}`,
    updateAdminNotification: (id) => `${baseUrl}/admin/notifications/${id}`,
    fetchNotifications: (searchKey, download, page) =>
      `${baseUrl}/admin/notifications?searchKey=${searchKey}&download=${download}&page=${page}&limit=10`,
    fetchSpecificNotification: (id) => `${baseUrl}/admin/notifications/${id}`,
    deleteSpecificNotification: (id) => `${baseUrl}/admin/notifications/${id}`,
  },
  support: {
    fetchSupportList: (
      searchKey,
      fromDate,
      toDate,
      status,
      download,
      page,
      user
    ) =>
      `${baseUrl}/admin/supports?entity=${user}&searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&download=${download}&page=${page}`,
    fetchSupportStats: `${baseUrl}/admin/supports/stats`,
    updateSupport: (id) => `${baseUrl}/admin/supports/${id}`,
    fetchSupportById: (id) => `${baseUrl}/admin/supports/${id}`,
  },

  offerRequests: {
    fetchOfferPlans: `${baseUrl}/admin/offer-plans`,
    createNewOffer: `${baseUrl}/offers`,
    fetchOffers: (searchKey, status, download, page) =>
      `${baseUrl}/admin/offers?searchKey=${searchKey}&approvalStatus=${status}&download=${download}&page=${page}`,
    fetchSpecificOffer: (id) => `${baseUrl}/offers/${id}`,
    deleteSpecificOffer: (id) => `${baseUrl}/offers/${id}`,
    editSpecificOffer: (id) => `${baseUrl}/offers/${id}`,
  },

  shipment: {
    fetchShipments: (searchKey, fromDate, toDate, status, download, page) =>
      `${baseUrl}/admin/shipments?searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&download=${download}&page=${page}&limit=10`,
    fetchShipmentBySpecificId: (id) => `${baseUrl}/admin/shipments/${id}`,
    fetchShipmentTrackingDetails: (id) =>
      `${baseUrl}/admin/shipments/${id}/track`,
  },
  subAdmin: {
    addSubAdmin: `${baseUrl}/admin/sub-admin`,
    updateSubAdmin: (id) => `${baseUrl}/admin/sub-admin/${id}`,
    fetchSubAdmins: `${baseUrl}/admin/sub-admin`,
    fetchSpecificSubAdmin: (id) => `${baseUrl}/admin/sub-admin/${id}`,
    deleteSubAdmin: (id) => `${baseUrl}/admin/sub-admin/${id}`,
  },
  reports: {
    fetchSalesReports: (fromMonth, toMonth, vendorId) =>
      `${baseUrl}/admin/report/sales?fromMonth=${fromMonth}&toMonth=${toMonth}&vendorId=${vendorId}`,

    fetchEarningsReports: (fromMonth, toMonth) =>
      `${baseUrl}/admin/report/earnings?fromMonth=${fromMonth}&toMonth=${toMonth}`,
    fetchOrderReports: (fromMonth, toMonth) =>
      `${baseUrl}/admin/report/orders?fromMonth=${fromMonth}&toMonth=${toMonth}`,
    fetchEarningOrderReports: (fromDate, toDate, searchKey, page, download) =>
      `${baseUrl}/admin/report/earnings/records?fromDate=${fromDate}&toDate=${toDate}&searchKey=${searchKey}&page=${page}&download=${download}`,
    fetchOrderStats: `${baseUrl}/admin/orders/stats`,
    fetchActiveShops: (searchKey) =>
      `${baseUrl}/vendor/shops/active?searchKey=${searchKey}`,
  },
};
export const categoriesUrl = {
  createCategory: `${baseUrl}/category/create-cat`,
  createSubCategory: `${baseUrl}/category/create-subcat`,
  deleteCategory: (id) => `${baseUrl}/admin/categories/${id}`,
  deleteSubCategory: (id) => `${baseUrl}/admin/sub-categories/${id}`,
  addLanguage: `${baseUrl}/category/add-lang`,
  deleteLanguage: (id) => `${baseUrl}/admin/languages/${id}`,
  addOccasion: `${baseUrl}/category/add-occasion`,
  deleteOccasion: (id) => `${baseUrl}/admin/occasions/${id}`,
  fetchActiveCategoriesNames: `${baseUrl}/category/category-names`,
  fetchCategories: `${baseUrl}/category`,
  fetchSubCategories: `${baseUrl}/category/sub-categories`,
  fetchLanguages: `${baseUrl}/category/languages`,
  fetchOccasions: `${baseUrl}/category/occasions`,
  updateCategory: `${baseUrl}/category/update-cat`,
  updateSubCategory: `${baseUrl}/category/update-subcat`,
  getCategoryById: `${baseUrl}/category/category`,
  getSubCategoryById: `${baseUrl}/category/sub-category`,
};
export const adminUrl = {
  fetchMeetings: (
    searchKey,
    startDate,
    toDate,
    selectedStatus,
    download,
    page
  ) =>
    `${baseUrl}/admin/meetings?searchKey=${searchKey}&fromDate=${startDate}&toDate=${toDate}&status=${selectedStatus}&download=${download}&page=${page}&limit=10`,
  fetchMeetingsStats: `${baseUrl}/admin/meetings/stats`,
  fetchCreditsHistory: `${baseUrl}/admin/credits`,
  joinMeet: `${baseUrl}/admin/meet/join`,
  category: {
    createCategory: `${AdminAuthBaseUrl}/categories`,
    deleteCategory: (id) => `${AdminAuthBaseUrl}/categories/${id}`,
    fetchCategories: (searchKey, status, page) =>
      `${AdminAuthBaseUrl}/categories?searchKey=${encodeURIComponent(
        searchKey || ""
      )}&status=${encodeURIComponent(status || "")}&page=${encodeURIComponent(
        page || 1
      )}&limit=10`,
    updateCategory: (id) => `${AdminAuthBaseUrl}/categories/${id}`,
    getCategoryById: (id) => `${AdminAuthBaseUrl}/categories/${id}`,
  },
  subCategory: {
    createSubCategory: `${AdminAuthBaseUrl}/sub-categories`,
    deleteSubCategory: (id) => `${AdminAuthBaseUrl}/sub-categories/${id}`,
    fetchSubCategories: (searchKey, status, page) =>
      `${AdminAuthBaseUrl}/sub-categories?searchKey=${encodeURIComponent(
        searchKey || ""
      )}&status=${encodeURIComponent(status || "")}&page=${encodeURIComponent(
        page || 1
      )}&limit=10`,
    updateSubCategory: (id) => `${AdminAuthBaseUrl}/sub-categories/${id}`,
    getSubCategoryById: (id) => `${AdminAuthBaseUrl}/sub-categories/${id}`,
  },
  language: {
    createLanguages: `${AdminAuthBaseUrl}/languages`,
    deleteLanguages: (id) => `${AdminAuthBaseUrl}/languages/${id}`,
    fetchLanguages: (page) => `${AdminAuthBaseUrl}/languages?page=${page}`,
    updateLanguages: (id) => `${AdminAuthBaseUrl}/languages/${id}`,
    getLanguageById: (id) => `${AdminAuthBaseUrl}/languages/${id}`,
  },
  occasion: {
    createOccasions: `${AdminAuthBaseUrl}/occasions`,
    deleteOccasions: (id) => `${AdminAuthBaseUrl}/occasions/${id}`,
    fetchOccasions: (page) => `${AdminAuthBaseUrl}/occasions?page=${page}`,
    updateOccasions: (id) => `${AdminAuthBaseUrl}/occasions/${id}`,
    getOccasionsById: (id) => `${AdminAuthBaseUrl}/occasions/${id}`,
  },
  offer: {
    fetchRequestOffers: (
      searchKey,
      approvalStatus,
      status,
      download,
      creatorEntity,
      page
    ) =>
      `${AdminAuthBaseUrl}/offers?searchKey=${searchKey}&approvalStatus=${approvalStatus}&status=${status}&download=${download}&creatorEntity=${creatorEntity}&page=${page}`,
    fetchRequestOfferById: (id) => `${AdminAuthBaseUrl}/offers/${id}`,
    addRequestOffers: `${AdminAuthBaseUrl}/offers`,
    updateRequestOffers: (id) => `${AdminAuthBaseUrl}/offers/${id}`,
    deleteRequestOffers: (id) => `${AdminAuthBaseUrl}/offers/${id}`,
    fetchOffersPlans: (searchKey, download, page) =>
      `${baseUrl}/admin/offer-plans?searchKey=${searchKey}&download=${download}&page=${page}`,
    createNewOffer: `${baseUrl}/admin/offer-plans`,
    fetchSpecificPlan: (id) => `${baseUrl}/admin/offer-plans/${id}`,
    deleteSpecificOfferPlan: (id) => `${baseUrl}/admin/offer-plans/${id}`,
    updateSpecificOfferPlan: (id) => `${baseUrl}/admin/offer-plans/${id}`,
  },
};
export const employeeUrl = {
  auth: {
    forgotPassword: `${EmployeeAuthBaseUrl}/forgot-password`,
  },
};
export const subAdminUrl = {
  vendor: {
    getVendors: `${SubAdminAuthBaseUrl}/getVendors`,
    getAllVendor: `${SubAdminAuthBaseUrl}/vendor`,
    getVendorById: `${SubAdminAuthBaseUrl}/vendors`,
    createVendor: `${SubAdminAuthBaseUrl}/createVendor`,
    updateVendor: `${SubAdminAuthBaseUrl}/vendors`,
    check_GSTIN_Exists: `${SubAdminAuthBaseUrl}/check_GSTIN_Exists`,
    checkBusinessIEC_Exists: `${SubAdminAuthBaseUrl}/checkBusinessIEC_Exists`,
    checkBusinessPAN_Exists: `${SubAdminAuthBaseUrl}/checkBusinessPAN_Exists`,
    checkEmailExists: `${SubAdminAuthBaseUrl}/checkEmailExists`,
    checkMobileNumberExists: `${SubAdminAuthBaseUrl}/checkMobileNumberExists`,
    getCitiesAndStates: `${SubAdminAuthBaseUrl}/getCitiesAndStates`,
  },
  profile: {
    update: `${SubAdminAuthBaseUrl}/update`,
  },
  subAdmin: {
    addSubAdmin: `${SubAdminAuthBaseUrl}/sub-admin`,
    updateSubAdmin: (id) => `${SubAdminAuthBaseUrl}/sub-admin/${id}`,
    fetchSubAdmins: `${SubAdminAuthBaseUrl}/sub-admin`,
    fetchSpecificSubAdmin: (id) => `${SubAdminAuthBaseUrl}/sub-admin/${id}`,
    deleteSubAdmin: (id) => `${SubAdminAuthBaseUrl}/sub-admin/${id}`,
  },
  employee: {
    getAllEmployees: (searchKey, status, download, page) =>
      `${SubAdminAuthBaseUrl}/employees?searchKey=${searchKey}&status=${status}&download=${download}&page=${page}`,
    getEmployeeById: (id) => `${SubAdminAuthBaseUrl}/employees/${id}`,
    updateEmployee: (id) => `${SubAdminAuthBaseUrl}/employees/${id}`,
    deleteEmployee: (id) => `${SubAdminAuthBaseUrl}/employees/${id}`,
    createEmp: `${SubAdminAuthBaseUrl}/employees`,
  },
  category: {
    createCategory: `${SubAdminAuthBaseUrl}/categories`,
    deleteCategory: (id) => `${SubAdminAuthBaseUrl}/categories/${id}`,

    fetchCategories: (searchKey, status, page) =>
      `${SubAdminAuthBaseUrl}/categories?searchKey=${encodeURIComponent(
        searchKey || ""
      )}&status=${encodeURIComponent(status || "")}&page=${encodeURIComponent(
        page || 1
      )}&limit=10`,
    updateCategory: (id) => `${SubAdminAuthBaseUrl}/categories/${id}`,
    getCategoryById: (id) => `${SubAdminAuthBaseUrl}/categories/${id}`,
  },
  subCategory: {
    createSubCategory: `${SubAdminAuthBaseUrl}/sub-categories`,
    deleteSubCategory: (id) => `${SubAdminAuthBaseUrl}/sub-categories/${id}`,
    fetchSubCategories: (searchKey, status, page) =>
      `${SubAdminAuthBaseUrl}/sub-categories?searchKey=${encodeURIComponent(
        searchKey || ""
      )}&status=${encodeURIComponent(status || "")}&page=${encodeURIComponent(
        page || 1
      )}&limit=10`,
    updateSubCategory: (id) => `${SubAdminAuthBaseUrl}/sub-categories/${id}`,
    getSubCategoryById: (id) => `${SubAdminAuthBaseUrl}/sub-categories/${id}`,
  },
  language: {
    createLanguages: `${SubAdminAuthBaseUrl}/languages`,
    deleteLanguages: (id) => `${SubAdminAuthBaseUrl}/languages/${id}`,
    fetchLanguages: (page) => `${SubAdminAuthBaseUrl}/languages?page=${page}`,
    updateLanguages: (id) => `${SubAdminAuthBaseUrl}/languages/${id}`,
    getLanguageById: (id) => `${SubAdminAuthBaseUrl}/languages/${id}`,
  },
  occasion: {
    createOccasions: `${SubAdminAuthBaseUrl}/occasions`,
    deleteOccasions: (id) => `${SubAdminAuthBaseUrl}/occasions/${id}`,
    fetchOccasions: (page) => `${SubAdminAuthBaseUrl}/occasions?page=${page}`,
    updateOccasions: (id) => `${SubAdminAuthBaseUrl}/occasions/${id}`,
    getOccasionsById: (id) => `${SubAdminAuthBaseUrl}/occasions/${id}`,
  },
  notification: {
    createNewNotification: `${SubAdminAuthBaseUrl}/notifications`,
    sendingAdminNotification: (id) =>
      `${SubAdminAuthBaseUrl}/notifications/send/${id}`,
    updateAdminNotification: (id) =>
      `${SubAdminAuthBaseUrl}/notifications/${id}`,
    fetchNotifications: (searchKey, download, page) =>
      `${SubAdminAuthBaseUrl}/notifications?searchKey=${searchKey}&download=${download}&page=${page}&limit=10`,
    fetchSpecificNotification: (id) =>
      `${SubAdminAuthBaseUrl}/notifications/${id}`,
    deleteSpecificNotification: (id) =>
      `${SubAdminAuthBaseUrl}/notifications/${id}`,
  },
  orders: {
    getOrders: (download, searchKey, fromDate, endDate, status, page) =>
      `${SubAdminAuthBaseUrl}/orders?searchKey=${encodeURIComponent(
        searchKey || ""
      )}&status=${encodeURIComponent(
        status || ""
      )}&fromDate=${encodeURIComponent(
        fromDate || ""
      )}&toDate=${encodeURIComponent(
        endDate || ""
      )}&download=${download}&page=${page}`,
  },
  meetings: {
    fetchMeetings: (
      searchKey,
      startDate,
      toDate,
      selectedStatus,
      download,
      page
    ) =>
      `${SubAdminAuthBaseUrl}/meetings?searchKey=${searchKey}&fromDate=${startDate}&toDate=${toDate}&status=${selectedStatus}&download=${download}&page=${page}&limit=10`,
    fetchMeetingsStats: `${SubAdminAuthBaseUrl}/meetings/stats`,
    joinMeet: `${SubAdminAuthBaseUrl}/meet/join`,
  },
  credit: {
    fetchCreditsHistory: `${SubAdminAuthBaseUrl}/credits`,
  },
  shipment: {
    fetchShipments: (searchKey, fromDate, toDate, status, download, page) =>
      `${SubAdminAuthBaseUrl}/shipments?searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&download=${download}&page=${page}&limit=10`,
    fetchShipmentBySpecificId: (id) => `${SubAdminAuthBaseUrl}/shipments/${id}`,
    fetchShipmentTrackingDetails: (id) =>
      `${SubAdminAuthBaseUrl}/shipments/${id}/track`,
  },
  offer: {
    fetchRequestOffers: (
      searchKey,
      approvalStatus,
      status,
      download,
      creatorEntity,
      page
    ) =>
      `${SubAdminAuthBaseUrl}/offers?searchKey=${searchKey}&approvalStatus=${approvalStatus}&status=${status}&download=${download}&creatorEntity=${creatorEntity}&page=${page}`,
    fetchRequestOfferById: (id) => `${SubAdminAuthBaseUrl}/offers/${id}`,
    addRequestOffers: `${SubAdminAuthBaseUrl}/offers`,
    updateRequestOffers: (id) => `${SubAdminAuthBaseUrl}/offers/${id}`,
    deleteRequestOffers: (id) => `${SubAdminAuthBaseUrl}/offers/${id}`,
    fetchOffersPlans: (searchKey, download, page) =>
      `${SubAdminAuthBaseUrl}/offer-plans?searchKey=${searchKey}&download=${download}&page=${page}`,
    createNewOffer: `${SubAdminAuthBaseUrl}/offer-plans`,
    fetchSpecificPlan: (id) => `${SubAdminAuthBaseUrl}/offer-plans/${id}`,
    deleteSpecificOfferPlan: (id) => `${SubAdminAuthBaseUrl}/offer-plans/${id}`,
    updateSpecificOfferPlan: (id) => `${SubAdminAuthBaseUrl}/offer-plans/${id}`,
  },
  plan: {
    fetchPlans: (searchKey, download, page) =>
      `${SubAdminAuthBaseUrl}/credits-plans?searchKey=${searchKey}&download=${download}&page=${page}`,
    addPlan: `${SubAdminAuthBaseUrl}/credits-plans`,
    fetchPlanById: (id) => `${SubAdminAuthBaseUrl}/credits-plans/${id}`,
    deleteSpecificPlan: (id) => `${SubAdminAuthBaseUrl}/credits-plans/${id}`,
    updateSpecificPlan: (id) => `${SubAdminAuthBaseUrl}/credits-plans/${id}`,
  },
  reports: {
    fetchSalesReports: (fromMonth, toMonth, vendorId) =>
      `${SubAdminAuthBaseUrl}/report/sales?fromMonth=${fromMonth}&toMonth=${toMonth}&vendorId=${vendorId}`,

    fetchEarningsReports: (fromMonth, toMonth) =>
      `${SubAdminAuthBaseUrl}/report/earnings?fromMonth=${fromMonth}&toMonth=${toMonth}`,
    fetchOrderReports: (fromMonth, toMonth) =>
      `${SubAdminAuthBaseUrl}/report/orders?fromMonth=${fromMonth}&toMonth=${toMonth}`,
    fetchEarningOrderReports: (fromDate, toDate, searchKey, page) =>
      `${SubAdminAuthBaseUrl}/report/earnings/records?fromDate=${fromDate}&toDate=${toDate}&searchKey=${searchKey}&page=${page}`,
    fetchOrderStats: `${SubAdminAuthBaseUrl}/orders/stats`,
    fetchActiveShops: (searchKey) =>
      `${baseUrl}/vendor/shops/active?searchKey=${searchKey}`,
  },

  offerPlans: {
    fetchOffers: (searchKey, download, page) =>
      `${SubAdminAuthBaseUrl}/offer-plans?searchKey=${searchKey}&download=${download}&page=${page}`,
    createNewOffer: `${SubAdminAuthBaseUrl}/offer-plans`,
    fetchSpecificPlan: (id) => `${SubAdminAuthBaseUrl}/offer-plans/${id}`,
    deleteSpecificOfferPlan: (id) => `${SubAdminAuthBaseUrl}/offer-plans/${id}`,
    updateSpecificOfferPlan: (id) => `${SubAdminAuthBaseUrl}/offer-plans/${id}`,
  },
  offerRequests: {
    fetchOfferPlans: `${SubAdminAuthBaseUrl}/offer-plans`,
    fetchOffers: (searchKey, status, download, page) =>
      `${SubAdminAuthBaseUrl}/offers?searchKey=${searchKey}&approvalStatus=${status}&download=${download}&page=${page}`,
    fetchSpecificOffer: (id) => `${baseUrl}/offers/${id}`,
    deleteSpecificOffer: (id) => `${baseUrl}/offers/${id}`,
    editSpecificOffer: (id) => `${baseUrl}/offers/${id}`,
  },
  user: {
    fetchUsers: (searchKey, download, page) =>
      `${SubAdminAuthBaseUrl}/customers?searchKey=${searchKey}&download=${download}&page=${page}`,
    fetchUserById: (id) => `${SubAdminAuthBaseUrl}/customers/${id}`,

    fetchUserOrders: (
      id,
      searchKey,
      fromDate,
      toDate,
      status,
      download,
      page
    ) =>
      `${SubAdminAuthBaseUrl}/customers/${id}/orders?searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&download=${download}&page=${page}`,
    fetchUserCredits: (
      id,
      searchKey,
      fromDate,
      toDate,
      status,
      download,
      page
    ) =>
      `${SubAdminAuthBaseUrl}/customers/${id}/credits?searchKey=${searchKey}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&page=${page}`,
    addCreditOffer: (id) =>
      `${SubAdminAuthBaseUrl}/customers/${id}/award-credits`,
  },

  support: {
    fetchSupportList: (
      searchKey,
      fromDate,
      toDate,
      status,
      download,
      page,
      user
    ) =>
      `${SubAdminAuthBaseUrl}/supports?entity=${user}&searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&download=${download}&page=${page}`,
    fetchSupportStats: `${SubAdminAuthBaseUrl}/supports/stats`,
    updateSupport: (id) => `${SubAdminAuthBaseUrl}/supports/${id}`,
    fetchSupportById: (id) => `${SubAdminAuthBaseUrl}/supports/${id}`,
  },
};

export default APIUrls;
