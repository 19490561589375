
import "./style.css";

const EjoyshopLoader = () => {
  return (
    <div className='h-screen w-full flex justify-center items-center bg-white'>
      <img
      alt="logo"
        src={
          "https://res.cloudinary.com/dhsfan19o/image/upload/v1718446510/EJOYSHOP-USER/ejoyshopLogo_hxsuap.jpg"
        }
        className='logo-image'
      />
    </div>
  );
};

export default EjoyshopLoader;
